<template>
  <div class="app-sign">
    <div class="app-sign-tabs">用户类型：
      <el-radio-group v-model="userType" style="width: 130px;" size="small">
        <el-radio-button
          v-for="{paramValue,paramName} in userTypeList"
          :key="paramValue"
          :label="+paramValue">{{ paramName }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <stu-app-sign-view v-if="userType===2" class="flex-1" :user-type="userType" :sign-count-list="signCountList" />
    <teacher-app-sign-view v-if="userType===3" class="flex-1" :user-type="userType" :sign-count-list="signCountList" />
  </div>
</template>

<script>
import StuAppSignView from '@/views/pages/studentDepartment/app-sign/components/stuAppSignView.vue'
import TeacherAppSignView from '@/views/pages/studentDepartment/app-sign/components/teacherAppSignView.vue'
export default {
  name: 'appSign',
  components: {
    TeacherAppSignView,
    StuAppSignView
  },
  data () {
    return {
      userType: 2,
      signCountList: [], // 签到总天数 类型
      userTypeList: [] // 用户类型 用于搜索 用户或学生
    }
  },
  async mounted () {
    await Promise.all([
      this.getSignCountList(),
      this.$http.getParamListByKey('searchUserType', this, 'userTypeList')
    ])
  },
  methods: {
    // 获取签到总天数的类型
    async getSignCountList () {
      try {
        const res = await this.$http.getSysDictionaryByKey('signCount')
        this.signCountList = res.data
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss">
.app-sign {
  @include flex-column;
  width: 100%;
  height: 100%;
  padding-top: var(--space-primary);
  background: #fff;

  .app-sign-tabs {
    padding-left: var(--space-primary);
    font-size: 14px;
    color: #595959;
  }
}
</style>
