import request from '@/service/request'

/* app签到 page接口
* @params {string} keyword
* @params {number} pageNum
* @params {number} pageSize
* @params {number} signCount 签到总天数
*/
export const pageAppSignApi = (params) => {
  return request.post('/sys/userSign/stat/page', params)
}

/* app签到  统计接口
* @params {string} classesId
* @params {string} collegeId
* @params {number} grade
* @params {string} majorId
*/
export const sumAppSignApi = (params) => {
  return request.post('/sys/userSign/stat/count', params)
}
