<!-- 学生签到 table -->
<template>
  <table-view
    class="stu-app-sign-view"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="学生名"
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword','college','major','class']"
        :filter-keys="['userType']"
        @on-search="renderTable(1)">
        <el-form-item label="是否连续签到" label-width="100px">
          <el-radio-group v-model="queryInfo.continueFlag" size="small">
            <el-radio-button :label="1">是</el-radio-button>
            <el-radio-button :label="0">否</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="签到总天数" label-width="88px">
          <el-select v-model="queryInfo.signCount" clearable style="width: 180px;" size="small">
            <el-option
              v-for="{paramValue,paramName} in signCountList"
              :key="paramValue"
              :label="paramName"
              :value="paramValue" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column v-if="queryInfo.userType===3" prop="userName" label="用户名" width="140px" />
      <el-table-column v-if="queryInfo.userType===2" prop="stuName" label="学生名" width="140px" />
      <el-table-column prop="signTime" label="签到时间" width="200px" />
      <el-table-column v-if="queryInfo.userType===2" prop="className" label="班级" />
      <el-table-column v-if="queryInfo.userType===2" prop="majorName" label="专业" />
      <el-table-column v-if="queryInfo.userType===3" prop="deptName" label="所属部门" />
      <el-table-column prop="collegeName" label="所属院系" />
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageAppSignApi } from '@/api/app-manage/app-sign-api'

export default {
  name: 'stuAppSignView',
  mixins: [tableView],
  props: {
    signCountList: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      queryInfo: {
        collegeId: null,
        majorId: null,
        classesId: null,
        signCount: null,
        continueFlag: 0,
        userType: 2
      }
    }
  },
  async mounted () {
    await this.renderTable(1)
  },
  methods: {
    async renderTable (pageNum) {
      await this.getTableData(pageAppSignApi, pageNum)
    }
  }
}
</script>
